import React from 'react';
import CountdownTimer from './CountdownTimer';
import logo from './journeestalents.jpg'
import './App.css';
import coverPhoto from './cover.jpg'

function App() {
  return (
    <div className="App">

      <header className="App-header">
      <div className="cover-photo-container">
      <img src={coverPhoto} className="cover-photo" alt="Cover" />
      <img src={logo} className="App-logo" alt="logo" />
      </div>
        <CountdownTimer />
      </header>
    </div>
  );
}

export default App;
